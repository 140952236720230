(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-background/views/banner-background.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-background/views/banner-background.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_groups=_svs.banner_groups||{};
_svs.banner_groups.banner_background=_svs.banner_groups.banner_background||{};
_svs.banner_groups.banner_background.templates=_svs.banner_groups.banner_background.templates||{};
svs.banner_groups.banner_background.templates.banner_background = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"banner-group-background\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"bannerItems") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
Handlebars.partials['components-banner_groups-banner_background-banner_background'] = svs.banner_groups.banner_background.templates.banner_background;
})(svs, Handlebars);


 })(window);